/**
 * creo un hook de contexto para definir el comportamiento de la pagina en APP.js en caso 
 * que haya una pagina de tamaño entero
 */
import React, { createContext, useState, useContext, Children, useEffect } from "react";

const configContext = createContext();
const changeConfigContext = createContext();

export function useConfigContext(){
    return useContext(configContext)
}

const ConfigProvider = (props) =>{
    
    const [esLogin, setEsLogin] = useState(false)
    const [es404, setEs404] = useState(false)

    useEffect(()=>{
        if(es404){
        props.setFullPageLayout(true)
        }else{
        props.setFullPageLayout(false)
        }
    },[es404])

    return (
        <configContext.Provider value={{esLogin,es404,setEsLogin,setEs404}}>
            {props.children}
        </configContext.Provider>
    )
}

export default ConfigProvider
