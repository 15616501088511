import React, { createContext, useState, useContext, Children } from "react";
import globalVar from "./global";
import { encriptarDatos } from "../utils/funciones/crypto";

const infoContext = createContext();
const changeInfoContext = createContext();

export function useInfoUserContext(){
    return useContext(infoContext)
}

export function useChangeInfoUserContext(){
    return useContext(changeInfoContext)
}

const UserInfoProvider = (props) =>{

    const [info, setInfo] = useState(globalVar);
    
    const cambiarUsuario = (value) => {
        window.localStorage.setItem('usuario', value);
    }

    const cambiarIduser = (value) => {
        var datCrpt = encriptarDatos(value)
        window.localStorage.setItem('idUsuario', datCrpt);
    }

    const cambiarRol= (value) => {
        window.localStorage.setItem('varR', value);
    }

    const cambiarIdEmpresa = async(value) => {
        //const valorCrypt=await encriptar(value)
        var datCrpt = encriptarDatos(value)
        window.localStorage.setItem('idEmpresa', datCrpt);
    }

    const cambiarNombreEmpresa = async(value) => {
        //const valorCrypt=await encriptar(value)
        window.localStorage.setItem('nombreEmpresa', value);
    }

    const borrarInfo = async() => {
        //const valorCrypt=await encriptar(value)
        window.localStorage.removeItem('usuario');
        window.localStorage.removeItem('idUsuario');
        window.localStorage.removeItem('varR');
        window.localStorage.removeItem('idEmpresa');
        window.localStorage.removeItem('nombreEmpresa');
        window.localStorage.removeItem('idAgencia');
        window.localStorage.removeItem('nombreAgencia');
    }

    const cambiarIdAgencia = async(value) => {
        //const valorCrypt=await encriptar(value)
        var datCrpt = encriptarDatos(value)
        window.localStorage.setItem('idAgencia', datCrpt);
    }

    const cambiarNombreAgencia = async(value) => {
        //const valorCrypt=await encriptar(value)
        window.localStorage.setItem('nombreAgencia', value);
    }


    return (
        <infoContext.Provider value={info}>
            <changeInfoContext.Provider value={[
                cambiarUsuario, 
                cambiarIduser, 
                cambiarRol, 
                cambiarIdEmpresa, 
                cambiarNombreEmpresa, 
                borrarInfo, 
                cambiarIdAgencia,
                cambiarNombreAgencia
                ]}>
                {props.children}
            </changeInfoContext.Provider>
        </infoContext.Provider>
    )
}

export default UserInfoProvider;