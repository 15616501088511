import React, { Component } from 'react';
import { Link, /*withRouter*/ } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


class SidebarSDQuito extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true}); 
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          {/* <a className="brand-logo" href="index.html"><img src={require('../../assets/images/logo.svg')} width="50" height="50"alt="logo" /></a> */}
          <Link to="/sdquito-inicio"><div className="brand-logo-mini"><img src={require('../../../assets/images/logoSDQuito.png')} width="55" height="55" alt="logo" /></div></Link>
        </div>
        <ul className="nav">
          {//Inicio de secciones del sistema
          }
          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Navegación</Trans></span>
          </li>
          <li className={ this.isPathActive('/sdquito-inicio') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/sdquito-inicio">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Inicio</Trans></span>
            </Link>
          </li>

          {/* Sidebar boletos*/}
          <li className={ this.isPathActive('/boletos') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ this.state.empresa ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('empresa') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-ticket"></i>
              </span>
              <span className="menu-title"><Trans>Boletos</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.empresa }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/boletos/ingresarBoleto') ? 'nav-link active' : 'nav-link' } to="/boletos/ingresarBoleto"><Trans>Ingresar Boleto</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/boletos/listaBoletos') ? 'nav-link active' : 'nav-link' } to="/boletos/listaBoletos"><Trans>Boletos Ingresados</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    //console.log(window.location.pathname.startsWith(path))
    //return this.props.location.pathname.startsWith(path);
    return window.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(SidebarSDQuito);
//export default Sidebar;