import React, { useContext, useEffect, useState, useLayoutEffect} from 'react';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';

import NavbarSDQuito from './sdquito/shared/Navbar';
import SidebarSDQuito from './sdquito/shared/Sidebar';
import FooterSDQuito from './sdquito/shared/Footer';

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import axios from 'axios';
import globalVar from './global';
import UserInfoProvider from './infoUserProvider.jsx';
import ConfigProvider, { useConfigContext } from './configContext.jsx';

const URI = process.env.REACT_APP_API_URL+'tokvalid'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

//class App extends Component {
const App = () => {

  let navigate = useNavigate();

  //hook para setear el estado de login
  const [logeado, setlogeado]=useState()

  //estado que detecta si el usuario esta activo
  const [userIsActive, setUserIsActive] = useState(true); 

  //tiempo de caducidad de la sesion
  const tiempo=1000*60*60 //60 minutos

  //creacion de temporizador para cierre de sesion
  var inactivo;
  function reiniciarTemporizador() {
    clearTimeout(inactivo);
    // Establecer el temporizador nuevamente después de cada interacción del usuario
    inactivo =setTimeout(()=>{
      setUserIsActive(false)
      document.cookie = `token=''; path=/; expires=${new Date(0)} ;samesite=strict;`
    }
    , tiempo);
  }

  const resetUserActivity = () => {
    setUserIsActive(true);
    reiniciarTemporizador()
  };

  //hook de efecto para detectar si el usuario esta activo
  useEffect(() => {

    // Inicializar el temporizador al cargar el componente
    reiniciarTemporizador();

    // Agregar event listeners para rastrear actividad del usuario
    //window.addEventListener('mousemove', resetUserActivity);
    window.addEventListener('keydown', resetUserActivity);
    window.addEventListener('click', resetUserActivity);

    // Limpiar el timeout y los event listeners cuando el componente se desmonta
    return () => {
      //window.removeEventListener('mousemove', resetUserActivity);
      window.removeEventListener('keydown', resetUserActivity);
      window.removeEventListener('click', resetUserActivity);
      clearTimeout(inactivo);
    };
  }, []);
  
  useEffect(()=>{
    validarSesion();
  },[userIsActive])

  //funcion para que valide el inicio de sesion en determinadas paginas
  /* async function validarSesion(){
    var tocaValidar=true //variable para saber si toca validar la sesion
    const paginasNoIncluidas = [
      '/activarUsuario',
      '/recuperar-contrasenia',
      '/nueva-contrasenia',
      '/sdquito-login',
      '/sdquito-registro', 
      '/login', 
      '/form-afiliacion', 
      '/error-pages/error-404', 
      '/error-pages/error-500', 
      ];

      for ( let i = 0; i < paginasNoIncluidas.length; i++ ) {
        var noValidar = window.location.pathname.includes(paginasNoIncluidas[i])
        if (noValidar==true) {
          tocaValidar=false
        }
      }
      if(tocaValidar){
        if(!token){
          window.alert('Sesión caducada')
          navigate('/login')
        }
      }
  } */

  async function validarSesion(){
      if(logeado){
        if(!token){
          setlogeado(false)
          window.alert('Sesión caducada')
          navigate('/login')
        }
      }
  }

  //metodo para encontrar la cookie
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  var token=getCookie('token')


  const validaciones = async() =>{//validaciones para el ruteo condicional
    if ( token ){
      try {
        const response = await axios.post(URI, {'token':`${token}`}, {withCredentials: true} )//llamado a la api para la validacion del token
        globalVar.usuario=response.data.user[0].nombre
        globalVar.idUser=response.data.user[0].id
        globalVar.rol=response.data.user[0].rol
        globalVar.correo=response.data.user[0].correo

        setlogeado(true)
      } catch (error) {
        console.log(error);
        setlogeado(false)
      }
    }else{
    setlogeado(false)
    }
  }

  //hook para llamar al token de sesion
  useEffect(()=>{
    validaciones();    
  },[])
  
  const [isFullPageLayout, setFullPageLayout]=useState()

  useEffect(()=>{
    validaciones();   
    onRouteChanged();
    
  },[window.location.pathname])


    let navbarComponent = !isFullPageLayout ? <Navbar/> : '';
    let sidebarComponent = !isFullPageLayout ? <Sidebar/> : '';
    let footerComponent = !isFullPageLayout ? <Footer/> : '';

    function onRouteChanged() {
      console.log("ROUTE CHANGED");
      window.scrollTo(0, 0);
      const fullPageLayoutRoutes = [
        '/activarUsuario',
        '/recuperar-contrasenia',
        '/nueva-contrasenia',
        '/sdquito-login',
        '/sdquito-registro', 
        '/login', 
        '/form-afiliacion', 
        '/user-pages/login-1', 
        '/user-pages/login-2', 
        '/user-pages/register-1', 
        '/user-pages/register-2', 
        '/user-pages/lockscreen', 
        '/error-pages/error-404', 
        '/error-pages/error-500', 
        '/general-pages/landing-page',
      ];
      for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
        if (window.location.pathname.includes(fullPageLayoutRoutes[i])) {
          setFullPageLayout(true)
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          break;
        } else {
  
          setFullPageLayout(false)
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
        }
      }
    }

    var ambiente=getCookie('ambiente')

    //condicional para setear el sidebar y las rutas que solo podra ver el ambiente de sdquito

    let navbarComponentSDQ = !isFullPageLayout ? <NavbarSDQuito/> : '';
    let sidebarComponentSDQ = !isFullPageLayout ? <SidebarSDQuito/> : '';
    let footerComponentSDQ = !isFullPageLayout ? <FooterSDQuito/> : '';

    if(ambiente=='sdquito'){
      return (
        <UserInfoProvider>
        <div className="container-scroller">
          { sidebarComponentSDQ }
          <div className="container-fluid page-body-wrapper">
            { navbarComponentSDQ }
            <div className="main-panel">
              <div className="content-wrapper">
                {logeado!=undefined && <AppRoutes logeado={logeado} ambiente={'sdquito'}/>}
              </div>
              { footerComponentSDQ }
            </div>
          </div>
        </div>
        </UserInfoProvider>
      );

    }else{

    return (
      <UserInfoProvider>
        <ConfigProvider setFullPageLayout={setFullPageLayout}>
        <div className="container-scroller">
          { sidebarComponent }
          <div className="container-fluid page-body-wrapper">
            { navbarComponent }
            <div className="main-panel">
              <div className="content-wrapper">
              {logeado!=undefined && <AppRoutes logeado={logeado} ambiente={'sistema'}/>}
              </div>
              { footerComponent }
            </div>
          </div>
        </div>
        </ConfigProvider>
      </UserInfoProvider>
    );

  }
}

export default (withRouter(App));

