import React, { Component, useEffect, useState } from 'react';
import { Link, /*withRouter*/ } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import axios from 'axios';

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const URI1 = process.env.REACT_APP_API_URL+'afiliada'
const URIsistema = process.env.REACT_APP_API_URL

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


//class Sidebar extends Component {
const Sidebar = () => {

  //para visualizar a la imagen logo de la empresa afiliada
  const [imagen, setImagen] = useState('')
  const obtenerImagen = async() => {
    try {
      const img = await axios.post(URI1, 
        {'opcion': 1,
          'idAfiliada': window.localStorage.idEmpresa?window.localStorage.idEmpresa:'-1'
        } 
    )
      setImagen(img.data[0][0].rutaImagen);
    
    } catch (error) {
      console.log(error);
    }
  }

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  useEffect(()=>{ 
    var cookieToken = getCookie('token')
    if(cookieToken){
    obtenerImagen();
  }
  },[])

  const [state, setState] = useState({});

  //toggleMenuState(menuState) {
    const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({[menuState] : false});
    } else if(Object.keys(state).length === 0) {
      setState({[menuState] : true});
    } else {
      Object.keys(state).forEach(i => {
        setState({[i]: false});
      });
      setState({[menuState] : true}); 
    }
  }

 /*  componentDidUpdate(prevProps) {
    if (props.location !== prevProps.location) {
      onRouteChanged();
    }
  } */

  useEffect(()=>{ 
      onRouteChanged();
  },[window.location.pathname])

  //onRouteChanged() {
    const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach(i => {
      setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setState({[obj.state] : true})
      }
    }));
 
  }

  //isPathActive(path) {
    const isPathActive = (path) => {
      //console.log(window.location.pathname.startsWith(path))
      //return props.location.pathname.startsWith(path);
      return window.location.pathname.startsWith(path);
    }
  
  //componentDidMount() {
    useEffect(()=>{
      onRouteChanged();
      // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
      const body = document.querySelector('body');
      document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
        
        el.addEventListener('mouseover', function() {
          if(body.classList.contains('sidebar-icon-only')) {
            el.classList.add('hover-open');
          }
        });
        el.addEventListener('mouseout', function() {
          if(body.classList.contains('sidebar-icon-only')) {
            el.classList.remove('hover-open');
          }
        });
      });
    },[])
    
  //}

  //render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          {/* <a className="brand-logo" href="index.html"><img src={require('../../assets/images/logo.svg')} width="50" height="50"alt="logo" /></a> */}
          <Link to="/dashboard"><div className="brand-logo-mini"><img src={require('../../assets/images/logo.png')} width="60" height="60" alt="logo" /></div></Link>
        </div>
        <ul className="nav">
          {//Inicio de secciones del sistema
          }
          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Empresa</Trans></span>
            <p className="ml-2 navbar-nav" >{window.localStorage.nombreEmpresa}</p>
            <div className='col-sm-12 mt-2 d-flex'>
              {imagen?(<img
                src={`${URIsistema}`+`uploads/imagenesAfiliadas/${imagen}`}
                alt="Logo de la empresa"
                style={{ maxWidth: '80px', maxHeight: '80px' }}
              />)
              :
              (<p>Sin logo disponible</p>)
              }
              </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Navegación</Trans></span>
          </li>
          <li className={ isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>

          {/* Sidebar empresa*/}
          <li className={ isPathActive('/empresa') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.empresa ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('empresa') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-briefcase"></i>
              </span>
              <span className="menu-title"><Trans>Empresa</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.empresa }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/empresa/datosEmpresa') ? 'nav-link active' : 'nav-link' } to="/empresa/datosEmpresa"><Trans>Datos</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/empresa/verAgencias') ? 'nav-link active' : 'nav-link' } to="/empresa/verAgencias"><Trans>Agencias</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/empresa/nominaEmpresa') ? 'nav-link active' : 'nav-link' } to="/empresa/nominaEmpresa"><Trans>Nómina</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/empresa/departamentosCargos') ? 'nav-link active' : 'nav-link' } to="/empresa/departamentosCargos"><Trans>Departamentos y Cargos</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          
          {/* Sidebar productos*/}
          <li className={ isPathActive('/productos') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.productos ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('productos') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-apps"></i>
              </span>
              <span className="menu-title"><Trans>Productos</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.productos }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/productos/mostrarProductos') ? 'nav-link active' : 'nav-link' } to="/productos/mostrarProductos"><Trans>Productos Débito</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/productosFacturacion/mostrarProductos') ? 'nav-link active' : 'nav-link' } to="/productosFacturacion/mostrarProductos"><Trans>Productos Facturación</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar clientes*/}
          <li className={ isPathActive('/clientes') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.clientes ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('clientes') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-account-multiple"></i>
              </span>
              <span className="menu-title"><Trans>Clientes</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.clientes }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/clientes/clientes') ? 'nav-link active' : 'nav-link' } to="/clientes/clientes"><Trans>Clientes</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/clientes/certificados') ? 'nav-link active' : 'nav-link' } to="/clientes/certificados"><Trans>Certificados</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar Afiliaciones antes ventas*/}
          <li className={ isPathActive('/afiliaciones') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.afiliaciones ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('afiliaciones') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-playlist-plus"></i>
              </span>
              <span className="menu-title"><Trans>Afiliaciones</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.afiliaciones }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/afiliaciones/afiliacionMasiva') ? 'nav-link active' : 'nav-link' } to="/afiliaciones/afiliacionMasiva"><Trans>Carga masiva de certificados</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/afiliaciones/afiliacionIndividual') ? 'nav-link active' : 'nav-link' } to="/afiliaciones/afiliacionIndividual"><Trans>Ingreso Individual</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/afiliaciones/consultarAfiliaciones') ? 'nav-link active' : 'nav-link' } to="/afiliaciones/consultarAfiliaciones"><Trans>Consultar Afiliaciones</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar Cobranzas*/}
          <li className={ isPathActive('/cobranzas') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.cobranzas ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('cobranzas') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-cash-usd"></i>
              </span>
              <span className="menu-title"><Trans>Cobranzas</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.cobranzas }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/cobranzas/precuotas-cuotas-debito') ? 'nav-link active' : 'nav-link' } to="/cobranzas/precuotas-cuotas-debito"><Trans>Precuotas/Cuotas Débito</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/cobranzas/ultimaNomina') ? 'nav-link active' : 'nav-link' } to="/cobranzas/ultimaNomina"><Trans>Última Nómina</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/cobranzas/gestionDebitos') ? 'nav-link active' : 'nav-link' } to="/cobranzas/gestionDebitos"><Trans>Gestión Débitos Bancarios</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/cobranzas/liquidacionesProductos') ? 'nav-link active' : 'nav-link' } to="/cobranzas/liquidacionesProductos"><Trans>Liquidaciones Empresas</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar Facturación*/}
          <li className={ isPathActive('/facturacion') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.facturacion ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('facturacion') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-receipt"></i>
              </span>
              <span className="menu-title"><Trans>Facturacion</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.facturacion }>
              <div>
                <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/facturacion/firmaElectronicaEmpresa') ? 'nav-link active' : 'nav-link' } to="/facturacion/firmaElectronicaEmpresa"><Trans>Firma Electrónica</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/facturacion/ventas') ? 'nav-link active' : 'nav-link' } to="/facturacion/ventas"><Trans>Ventas</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar Contabilidad*/}
          <li className={ isPathActive('/contabilidad') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.contabilidad ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('contabilidad') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-calculator"></i>
              </span>
              <span className="menu-title"><Trans>Contabilidad</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.contabilidad }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/contabilidad/estadosCuenta') ? 'nav-link active' : 'nav-link' } to="/contabilidad/estadosCuenta"><Trans>Estados de Cuenta</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar emails*/}
          <li className={ isPathActive('/mails') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.mails ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('mails') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-email"></i>
              </span>
              <span className="menu-title"><Trans>eMails</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.mails }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/correos/remitentes') ? 'nav-link active' : 'nav-link' } to="/correos/remitentes"><Trans>Remitentes</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/correos/plantillas') ? 'nav-link active' : 'nav-link' } to="/correos/plantillas"><Trans>Plantillas</Trans></Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/correos/campaigns') ? 'nav-link active' : 'nav-link' } to="/correos/campaigns"><Trans>Campañas</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar Reportes*/}
          <li className={ isPathActive('/reportes') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.reportes ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('reportes') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-file-document"></i>
              </span>
              <span className="menu-title"><Trans>Reportes</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.reportes }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/clientes/resAfiliadaClientes') ? 'nav-link active' : 'nav-link' } to="/clientes/resAfiliadaClientes"><Trans>Reportes de Cartera</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar IFIS*/}
          <li className={ isPathActive('/ifis') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.ifis ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('ifis') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-wallet-membership"></i>
              </span>
              <span className="menu-title"><Trans>RES Cobranzas IFIS</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.ifis }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/clientes/resAfiliadaClientes') ? 'nav-link active' : 'nav-link' } to="/clientes/resAfiliadaClientes"><Trans>Emisores Bancarios IFIS</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Sidebar Administrador*/}
          <li className={ isPathActive('/Administracion') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ state.administracion ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('administracion') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi mdi-account-star"></i>
              </span>
              <span className="menu-title"><Trans>Administración general</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.administracion }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ isPathActive('/administracion/afiliadas') ? 'nav-link active' : 'nav-link' } to="/administracion/afiliadas">Empresas Afiliadas</Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/administracion/usuarios') ? 'nav-link active' : 'nav-link' } to="/administracion/usuarios">Usuarios</Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/administracion/emisores') ? 'nav-link active' : 'nav-link' } to="/administracion/emisores">Emisores</Link></li>
                  <li className="nav-item"> <Link className={ isPathActive('/administracion/servidoresCorreo') ? 'nav-link active' : 'nav-link' } to="/administracion/servidoresCorreo">Servidores de Correo</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  //}
}

export default withRouter(Sidebar);
//export default Sidebar;