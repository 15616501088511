import React, { Component } from 'react';
// import { Trans } from 'react-i18next';
class FooterSDQuito extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Sitio administrado por <a href="https://www.rescobranzas.com/" target="_blank" rel="noopener noreferrer">www.rescobranzas.com </a></span>
            
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterSDQuito;