import CryptoJS from 'crypto-js';

const pass = process.env.REACT_APP_PASSWORD

/**
 * Metodo para encriptar los datos con AES, la llave privada está en .env
 * @param {String} texto 
 * @returns 
 */
export const encriptarDatos = (texto) => {
    if(texto){
        const textoCifrado = CryptoJS.AES.encrypt(texto.toString(), pass.toString()).toString();
        const textoCifrado1 = textoCifrado.replace(/\//g, '_');

        return(textoCifrado1)
    }else{
        return null
    }
}

/**
 * Metodo para desencriptar los datos con AES, la llave privada está en .env
 * @param {String} textoCifrado 
 * @returns 
 */
export const desencriptarDatos = (textoCifrado) => {
    if(textoCifrado){
        const textoDescifrado = textoCifrado.replace(/_/g, '/');
        const bytes = CryptoJS.AES.decrypt(textoDescifrado.toString(), pass.toString())
        const textoDescifrado1 = bytes.toString(CryptoJS.enc.Utf8)
    
        return(textoDescifrado1)
    }else{
        return null
    }
}