import React, { Component, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link , useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import globalVar from '../../global';
import {Button, Collapse} from 'react-bootstrap';

const NavbarSDQuito = () => {
  
  const toggleOffcanvas=()=>{
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar=()=> {
    document.querySelector('.right-sidebar').classList.toggle('open');
  } 

  //const navigate = useHistory();
  const navigate = useNavigate();

  //metodo para navegar a la seleccion de empresa
  const handleEmpresaSeleccion = () =>{
    navigate('/seleccionEmpresa')
  }
  //Metodo para cerrar sesion
  const CerrarSesionHandler=async()=> {
    async function cambiarToken(){
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "ambiente=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    await cambiarToken();
    window.localStorage.removeItem('idEmpresa');
    globalVar.usuario='';
    setTimeout(() => {navigate("/sdquito-login")}, 500);
  } 

  //metodo para enviar a la configuracion de usuario
  const handleConfUser = () =>{
    navigate('/actualizarDatos')
  }

    return (
      <nav className="navbar p-0 fixed-top flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link to="/dashboard"><img src={require('../../../assets/images/logoSDQuito.png')} alt="logo" className="navbar-brand" /></Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
  
          <ul className="navbar-nav navbar-nav-right">
            
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <p className="mb-0 d-none d-sm-block navbar-profile-name">Hola {globalVar.usuario}</p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <h6 className="p-3 mb-0"><Trans>Perfil</Trans></h6>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleConfUser} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Datos de Usuario</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-lock-reset text-warning"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Cambiar Contraseña</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={CerrarSesionHandler} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1"><Trans>Cerrar Sesión</Trans></p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>
    );
  
}

export default NavbarSDQuito;
